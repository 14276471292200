// Import
import { createApp, defineAsyncComponent } from "vue"

// Components
// import VideoBackground from 'vue-responsive-video-background-player'
// import YouTube from 'vue3-youtube'
// import store from './store'
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Navigation, Pagination, EffectFade } from 'swiper/core';
// import VueClickAway from "vue3-click-away";
// import VueCookies from 'vue3-cookies';

// Mixins
// import { sitedata } from '@/js/mixins/sitedata';

// Styles
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css'

// Declare
declare const window: any;

// App main
const main = async () => {
    const app = createApp({
        delimiters: ['${', '}'],
        components: {
			SiteMenu: defineAsyncComponent(() => import(/* webpackChunkName: "SiteMenu" */ '@/js/components/SiteMenu.vue')),
			Swiper,
			SwiperSlide
        },
        data: () => ({
			megahover: false,
			scroll_position: 0,
			scroll_direction: '',
		}),
        methods: {
			handleScroll: function (event) {
				this.scroll_direction = (document.body.getBoundingClientRect()).top > this.scroll_position ? 'up' : 'down';
				this.scroll_position = (document.body.getBoundingClientRect()).top;

				if (this.scroll_direction === 'down') {
					document.body.classList.add('scrolled');
				}
				else if (this.scroll_direction === 'up') {
					document.body.classList.remove('scrolled')
				}
			},
			toggleMobileElement(e) {
				e.target.parentNode.classList.toggle('open')
			},
        },
		created() {
			window.addEventListener('scroll', this.handleScroll, { passive: true });
		},
		destroyed() {
			window.removeEventListener('scroll', this.handleScroll);
		},
	   beforeMount() {
			//Get sitedata
			// store.commit('app/setSiteUrl', window.siteData.siteUrl)
			// store.commit('app/setCsrfToken', window.siteData.csrf)
	   },
		// setup() {
		// 	const onSwiper = (swiper) => {
		// 		// console.log(swiper);
		// 	};
		// 	const onSlideChange = () => {
		// 		// console.log('slide change');
		// 	};
		// 	return {
		// 		onSwiper,
		// 		onSlideChange,
		// 		EffectFade,
		// 	};
		// },
    });

	SwiperCore.use([Navigation, Pagination, EffectFade]);

	// app.use(store);
	// app.use(VueClickAway)
	// app.use(VueCookies);

	// Component liberaries
	// app.component('video-background', VideoBackground);
	// app.component('YouTube', YouTube);
	// app.component('Swiper', Swiper);
	// app.component('SwiperSlide', SwiperSlide);

	//Mixin
	// app.mixin(sitedata)

    // Mount the app
    const vm = app.mount('#app');
    return vm;
};

// Execute async function
main().then((vm) => {
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
